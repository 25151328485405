// p17137-aurobay-iaa-mobili-ello
// G-H3GRYZRM5P
// Au-lB4VCTKKEt2r_fk6rmw
// f7e4fe7628dc9e44502f96ed9be5180e|9655fb0882805192fac17e279ced64da4942fbdea881f0dea9f2eda5f6783a28c0588a07e52e7fa9582a430d697cb55c88607ce9c90d6ca43909819404c7e6564f96

import generateUniqueSessionId from '../../../lib/generateUniqueSessionId.js'

const buttonBackground = "#784696";
const buttonText = "#ffffff";

const checkValue = (value, defaultValue) => {
  return (typeof(value) !== 'undefined') ? value : defaultValue
}
const darkMode = false
const styleParams = {
  mainColor: buttonBackground,
  secondaryColor: buttonText,
}
const buttons = [
  {title: "Performance Powertrains"},
  {title: "Powering Champions"},
  {title: "Who we are"},
  {title: "Claim FREE Gift"}
].sort(() => Math.random() - 0.5);
const style =           {
  // basic colors
  brandColor: checkValue(styleParams.brandColor, '#ffffff'),
  secondaryBrandColor: checkValue(styleParams.mainColor, 'lightblue'),
  chatBackgroundColor: (darkMode) ? '#000000' : '#0B1530', // the background color in the actual chat
  // cards
  cardBackground: (darkMode) ? '#333333' : '#ebe8e8', // the card background
  cardTextColor: checkValue(styleParams.cardTextColor, (darkMode) ?  '#ffffff' : '#000000'),// cards
  borderRadius: '8px', // cards + buttons
  imagesHaveBorders: true,
  // Users
  userTextColor: '#ffffff',// user options
  userBackgroundColor: "#784696",
  // Text Input
  UIButtonBackgroundColor: '#959595', // textInput send button
  UIButtonStrokeColor: 'white', // textInput send button
  textInputColor: '#666666', // textInput options
  textInputBorderRadius: '16px', // textInput options
  typingIndicator: (darkMode) ? 'rgb(200,200,200)': 'rgb(55, 55, 55)',
}

const config = {
  darkMode: false,
  url: 'https://adchat-ai.uc.r.appspot.com/b',
  productionMode: true,
  hasBorder: false,
  language: 'EN',
  logoURL: 'images/aurobay-sema/logo.png',
  rightLogoURL: 'images/aurobay-sema/logo-right.png',
  roundLogo: false,
  headerBackgroundPosition: '16px 50%',
  headerBackgroundColor: 'white',
  introPostback: buttons[0].title,
  // hasHeaderButton: true,
  // headerButtonPostback: "Start Again",
  chatHeightOffset: 80,
  slugMode: true,
  slug: "f7e4fe7628dc9e44502f96ed9be5180e|9655fb0882805192fac17e279ced64da4942fbdea881f0dea9f2eda5f6783a28c0588a07e52e7fa9582a430d697cb55c88607ce9c90d6ca43909819404c7e6564f96",
  headerExitURL: "./index.html",
  firstFrameExitMode: false,
  headerButtonCopy: 'Return to start',
  firstFrameExitURL: '',
  chatTimingMS: 600,
  imagesHaveBorders: true,
  ySpaceBetweenCards: '6px',
  sessionId: generateUniqueSessionId(),
  platform: 'landing-page-en',
  hasInput: false,
  conversationHasInput: false,
  version: '1.0',
  inputConfig: {
    textInputWidth: '80%',
    promptCopy: ""
  },
  firstFrame: {
    inputConfig: {
      textInputWidth: '80%',
      promptCopy: ""
    },
  },
  pixels: {},
  exits: {
  },
  exitRedirects: {
  },
  simulatedConversation: [
    {
      type: 'image',
      src: './images/aurobay-sema/first-frame.mp4',
      imagesHaveBorders: false,
    },
    {
      type: 'brandResponse',
      text: `See it at **SEMA**. Central Hall, Racing & Performance, Booth 23915.`,
      time: 1500,
    },
    {
      type: 'quickReplies',
      text: `Discover Aurobay 👇`,
      size: 'large',
      buttons: buttons,
      time: 2000,
    }
  ]
}

export default {
  style: style,
  config: config,
}
