import TemplateSocialView from '../views/TemplateSocialView.svelte';
import data from './data/2023/17146-aurobay-sema-social.js';


const app = new TemplateSocialView({
	target: document.body,
	props: {
        style: data.style,
        config: data.config,
	}
});

export default app;
