<script>
    import { onMount, tick } from "svelte";
    import { fly } from 'svelte/transition';

    import ChatComponent from "../components/core/ChatComponent.svelte";
    import SimulatedConversation from "../components/core/SimulatedConversation.svelte";
    import PixelTracking from "../components/core/PixelTracking.svelte";
    import InputContainer from "../components/core/InputContainer.svelte";
    import URLToExitMap from "../lib/URLToExitMap";
    import Analytics from "../lib/Analytics";
	  import SocialHeader from "../components/core/SocialHeader.svelte";
    import { createEventDispatcher } from "svelte";

    export let style;
    export let config;
    export let height = "100%";
    export let width = "100%";
    export let headerHeight = 80;
    export let inputHeight = 67;

    // is true if config.hasBorder isn't "false"
    export let hasBorder = (config.hasBorder == false) ? false : true;

    $: chatHeight = (clientHeight - headerHeight - inputHeight) + 'px';

    let dispatch = createEventDispatcher();

    let activePixel = null;
    let conversationMode = false;
    let startingPhrase = "";
    let hasEngaged = false;
    let intentCount = 0;
    let intentName;
    let lastTypedQuestion;
    let submissionPhrase = '';
    let clientHeight
    let chatHeight = (clientHeight - headerHeight) + 'px';

    const analytics = new Analytics(config);
    const exits = new URLToExitMap(config.exits);

    inputHeight = (config.conversationHasInput) ? inputHeight : 0;
  
    onMount(() => {
      analytics.registerImpression();
    });
  
    function handleHeaderClick() {
      if(config.headerButtonPostback){
        return;
      }
      analytics.registerGenericEvent("headerClick");
      if (config.headerExitURL) {
        handleOpenURL({ detail: config.headerExitURL });
      } else {
        dispatch("headerClick");
      }
    }
    
    function handleLogoClick(){
      if(config.headerLogoURL){
        handleOpenURL({ detail: config.headerLogoURL });
      } else {
        handleHeaderClick();
      }
    }
    function handlePostback(event) {
      conversationMode = true;
      if(config.pixels[event.detail]){
        activePixel = event.detail;
      }
      if (!hasEngaged) {
        hasEngaged = true;
      }
      startingPhrase = event.detail;
    }
  
    function handleChatSubmit(event) {
      lastTypedQuestion = event.detail.wasTyped ? event.detail.phrase : "";
      conversationMode = true;
    }
  
    function handleTextSubmit(event) {
      startingPhrase = event.detail;
      lastTypedQuestion = startingPhrase;
      conversationMode = true;
    }
  
    function handleOpenURL(event) {
      const url = event.detail;
      const clickTag = config.clickTag || "";
      
      if (!exits.exits) {
        trackExit(url, config.sessionId, exitName);
        window.open(clickTag + url, "_blank");
        return;
      }
      trackExit(url, config.sessionId);
      const exitName = exits.getMapping(url);
      if (!exitName || typeof Enabler === "undefined") {
        window.open(clickTag + url, "_blank");
      } else {
        Enabler.exit(exitName, url);
      }
    }
  
    function firstFrameClickHandler(event){
      analytics.registerGenericEvent("firstFrameClick");
      if(!config.firstFrameExitMode){
        handlePostback({detail: config.introPostback || "Start"})
        return false
      } else {
        handleOpenURL({detail: config.firstFrameExitURL})
      }
    }

    function trackExit(url, sessionId) {
      const http = new XMLHttpRequest();
      const fileName = (config.slugMode) ? config.slug : config.projectId;
      const base = (config.slugMode) ? `${config.url}/hash/${fileName}.json` : `${config.url}/chat/${fileName}.json`
      const params = `?platform=${config.platform || 'display'}&version=${
        config.version
      }&session=${sessionId}&url=${encodeURIComponent(url)}`;
      http.open("POST", encodeURI(base + params), true);
      http.onreadystatechange = function() {
        if (http.readyState == 4 && http.status == 200) {
          return http.responseText;
        }
      };
      const exitName = exits.getMapping(url);
      if(exitName && config.pixels[exitName]){
        activePixel = exitName;
      } else{
        if(config.pixels[url]){
          activePixel = url;
        } else {
          activePixel = "exit-external";
        }
      }
      http.send(params);
    }

    function handleTextChange(){}
  
    async function handleHeaderButtonClick(event){
      event.stopPropagation();
      event.stopImmediatePropagation();
      if(config.headerButtonPostback){
        submissionPhrase = ''
        await tick()
        submissionPhrase = config.headerButtonPostback
        handlePostback({detail: config.introPostback || "Start"})
        return
      } else {
        analytics.registerGenericEvent("headerButtonClick");
        if(config.headerExitURL){
          handleOpenURL({detail: config.headerExitURL})
        } else {
          dispatch("headerButtonClick");
        }
        event.stopImmediatePropagation()
      }
        
    }
  
    function intentNameCallback(intentName) {
      intentCount++;
      intentName = intentName;
      if (intentCount === 1) {
        activePixel = "engaged-once";
      }
      if (intentCount === 2) {
        activePixel = "engaged-multiple";
      }
      if (typeof Enabler !== "undefined") {
        Enabler.counter("Engagement");
      }
      if (config.pixels && config.pixels[intentName]) {
        activePixel = config.pixels[intentName];
      }
    }
  
  </script>
  
  <div class={`ad-container overflow-hidden relative box-border ${(hasBorder) ? 'border-neutral-500 border-[1px]' : ''} h-full max-w-[500px] mx-auto`}
    bind:clientHeight={clientHeight}
    style={`
        height: ${height};
        width: ${width};
        background-color: ${style.chatBackgroundColor || 'white'};
      `}
    >
      <SocialHeader 
          isRound={config.roundLogo}
          logoPosition={config.logoPosition || "left"} 
          hasButton={(!config.buttonInFirstFrame) ? (config.hasHeaderButton && conversationMode) : config.hasHeaderButton}
          headerButtonCopy={config.headerButtonCopy}
          rightLogoURL={config.rightLogoURL}
          logoURL={config.logoURL}
          backgroundColor={style.chatBackgroundColor}
          darkMode={style.darkMode}
          style={style}
          on:headerClick={handleHeaderClick}
          on:logoClick={handleLogoClick}
          on:buttonClick={handleHeaderButtonClick}
      />

    {#if conversationMode}
      <div class="absolute w-full"
        style={`top: ${headerHeight}px;`}>
        <ChatComponent
          style={style}
          width={width}
          darkMode={style.darkMode}
          height={chatHeight}
          chatHeight={chatHeight}
          config={config}
          centerCardButton={config.centerCards}
          startingPhrase={startingPhrase}
          submissionPhrase={submissionPhrase}
          includeInput={config.conversationHasInput}
          intentNameCallback={intentNameCallback}
          carouselArrowButtonY={config.carouselArrowButtonY}
          chatTimingMS={config.chatTimingMS}
          analytics={analytics}
          ySpaceBetweenCards={config.ySpaceBetweenCards}
          trackingID={intentName}
          on:textSubmit={handleChatSubmit}
          on:openUrl={handleOpenURL}
        >
        </ChatComponent>
      </div>
    {:else }
      <div out:fly={{y: 600, duration: 500}}
        style={`
          background-image: ${config.backgroundImage};
          height: 100%;
        `}
      >
        <div class="intro absolute w-full h-[50%] cursor-pointer" 
              on:click={firstFrameClickHandler}
              on:keydown={firstFrameClickHandler}
              style={`top: ${config.header}; `}
        ></div>
      <div class="simulation absolute w-full" 
        style={`
          ${(config.firstFrame.simulationBottom) ? 'bottom:' + config.firstFrame.simulationBottom : ''};
         ${(config.firstFrame.simulationTop) ? 'top:' + `${headerHeight + 4}px;` : ''};
         `
        }>
        <div class="h-full" 
          style={`min-height: ${chatHeight};`}>
          <SimulatedConversation
            style={style}
            darkMode={style.darkMode}
            hasInput={config.hasInput}
            content={config.simulatedConversation}
            width='100%'
            chatTimingMS={config.chatTimingMS}
            analytics={analytics}
            ySpaceBetweenCards={config.ySpaceBetweenCards}
            conversationHeight={chatHeight}
            imagesHaveBorders={config.imagesHaveBorders}  
            on:postback={handlePostback}
            on:openUrl={handleOpenURL}
            on:imageClick={firstFrameClickHandler}
          />
        </div>
      </div>
    </div>
      {#if (!conversationMode) && config.hasInput}
        <div class="absolute bottom-0 w-full bg-white"
          style={`linear-gradient(to top, ${config.chatBackgroundColor} 87%, transparent 0);`}>
          <InputContainer
            style={style}
            inputConfig={config.firstFrame.inputConfig}
            on:textChange={handleTextChange}
            on:textSubmit={handleTextSubmit}
          />
        </div>
      {/if}
    {/if}
    {#if config.pixels}
      <PixelTracking
        pixels={config.pixels}
        pixelName={activePixel}
      />
    {/if}
  </div>
  
  <style>
    :global(body) {
      padding: 0;
      font-weight: 400;
    }
  </style>
  